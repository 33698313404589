<template>
  <v-container
    class="container-full-size"
  >
    <v-card
      v-if="openPhysicalEvaluationLoading"
      color="basil"
      style="margin-top: 0;"
      flat
      class="card-custom-loading"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-card>
    <v-card
      v-if="!openPhysicalEvaluationLoading"
      color="basil"
      style="margin-top: 0;"
      flat
    >
      <div
        v-if="parent.showPtNotes"
        class="client-pt-notes"
      >
        <div>
          <div>
            <v-textarea
              v-model="parent.ptNotes.physical_evaluation"
              :label="str['physical_evaluation_notes']"
              :rows="3"
              hide-details
            />
          </div>
        </div>
      </div>
      <v-col
        cols="12"
        class="row-flex-align-space"
      >
        <div>
          <div
            v-if="hasFilterDates"
            class="datepicker-element"
          >
            <v-menu
              v-model="filterDatepickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterDatesText"
                  :label="str['interval_dates']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                />
              </template>
              <v-date-picker
                v-model="filterDates"
                range
                style="margin: 0;"
                @change="changeDates()"
                :locale="datepickerLanguage"
              />
            </v-menu>
          </div>
        </div>
        <div>
          <v-btn
            v-if="showCreateButton && client"
            color="success"
            class="mr-4"
            @click="openCreateDialog()"
          >
            {{ str[showCreateButton.label] ? str[showCreateButton.label] : showCreateButton.label }}
          </v-btn>
          <v-btn
            v-if="parent.showPtNotes"
            color="secondary"
            :class="{'mr-4': !hideCompareButton}"
            @click="parent.savePtNotes()"
          >
            {{ str['save_notes'] }}
          </v-btn>
          <v-btn
            v-if="!hideCompareButton"
            color="warning"
            class="mr-0"
            :disabled="!physicalEvaluations.length"
            @click="openPhysicalEvaluationsToCompare()"
          >
            {{ str['compare_physical_evaluations'] }}
          </v-btn>
        </div>
      </v-col>
      <div>
        <v-tabs
          v-if="showTabs"
          v-model="physicalEvaluationTab"
          background-color="transparent"
          color="secondary"
          style="margin-top: 0;"
          grow
          show-arrows
        >
          <v-tab
            v-for="peTab in physicalEvaluationTabs"
            :key="peTab.id"
          >
            {{ peTab.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="physicalEvaluationTab"
        >
          <v-tab-item
            v-for="peTab in physicalEvaluationTabs"
            :key="peTab.id"
          >
            <div
              v-if="physicalEvaluationTabs[physicalEvaluationTab].id === 'list'"
            >
              <v-card-text
                v-if="!physicalEvaluations.length && isLoading && !feedbackTypes"
                class="text-center"
                style="padding: 40px 0;"
              >
                {{ str['processing'] }}
              </v-card-text>
              <v-card-text
                v-if="!physicalEvaluations.length && !isLoading && !feedbackTypes"
                class="text-center"
                style="padding: 40px 0;"
              >
                {{ str['no_physical_evaluations'] }}
              </v-card-text>
              <v-list
                v-if="!feedbackTypes"
                two-line
                style="min-height: 250px; padding: 0;"
              >
                <div
                  v-for="physicalEvaluation in physicalEvaluations"
                  :key="physicalEvaluation.id"
                >
                  <v-list-item
                    @click="openPhysicalEvaluation(physicalEvaluation)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="row-flex-align-space">
                          <div>
                            <v-icon
                              v-if="showChanges"
                              color="info"
                              dark
                              class="cursor-hover mr-1"
                              @click.stop="openPhysicalEvaluationChanges(physicalEvaluation)"
                            >
                              mdi-information
                            </v-icon>
                            <span
                              v-if="physicalEvaluation.type !== types.return"
                              :class="[returnPhysicalEvaluationDate && returnPhysicalEvaluationDate >= physicalEvaluation.date ? 'error--text' : '']"
                            >
                              {{ str['physical_evaluation'] }}{{ showId ? (' ' + physicalEvaluation.id) : '' }} - 
                            </span>
                            <span v-if="physicalEvaluation.type === types.initial_quiz">
                              {{ str['initial_physical_evaluation'] }} - 
                            </span>
                            <span v-if="physicalEvaluation.type === types.return">
                              {{ str['return_physical_evaluation'] }} - 
                            </span>
                            <span v-if="physicalEvaluation.type === types.newplans">
                              {{ str['new_plans_physical_evaluation'] }} - 
                            </span>
                            <span v-bind:class="(physicalEvaluationStatusDict[physicalEvaluation.status] ? physicalEvaluationStatusDict[physicalEvaluation.status].type : 'default') + '--text'">
                              {{ physicalEvaluationStatusDict[physicalEvaluation.status] ? physicalEvaluationStatusDict[physicalEvaluation.status].label : str['n_a'] }}
                            </span>
                            <span
                              v-if="showFeedbackEmployeeName && physicalEvaluation.employee_name"
                              class="ml-2 default-text"
                            >
                              ({{ str['employee'] + ': ' + physicalEvaluation.employee_name }})
                            </span>
                            <span
                              v-if="showWeightVariationAlert && (physicalEvaluation.flags & (1 << 0)) !== 0"
                              class="ml-2 error--text"
                            >
                              ({{ str['little_evolution'] }})
                            </span>
                            <span
                              v-if="saveTrainingPlanChanges && physicalEvaluation.changes && physicalEvaluation.changes.indexOf(saveTrainingPlanChanges.key) > -1"
                              class="ml-2 warning--text"
                            >
                              ({{ saveTrainingPlanChanges.key }})
                            </span>
                          </div>
                          <div v-if="showNotes && physicalEvaluation.notes">
                            <v-chip
                              color="blue"
                              @click.stop="showInformation(physicalEvaluation.notes)"
                              class="chip-button"
                            >
                              {{ str['notes'] }}
                            </v-chip>
                          </div>
                        </div>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="showPhysicalEvaluationDateNext"
                      >
                        {{ physicalEvaluation.date }} / {{ physicalEvaluation.date_next }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="!showPhysicalEvaluationDateNext"
                      >
                        {{ physicalEvaluation.date }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </v-list>
              <v-list
                v-if="feedbackTypes"
                two-line
                style="min-height: 250px; padding: 0;"
              >
                <v-tabs
                  v-model="physicalEvaluationTabType"
                  background-color="transparent"
                  color="secondary"
                  style="margin-top: 0;"
                  grow
                  show-arrows
                  @change="changePhysicalEvaluationTabType"
                >
                  <v-tab
                    v-for="fdType in feedbackTypes"
                    :key="fdType.id"
                  >
                    {{ str[fdType.tab] ? str[fdType.tab] : fdType.tab }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="physicalEvaluationTabType"
                >
                  <v-tab-item
                    v-for="fdType in feedbackTypes"
                    :key="fdType.id"
                  >
                    <div class="feedback-type-tab">
                      {{ str['no_physical_evaluations'] }}
                    </div>
                    <div
                      v-for="physicalEvaluation in physicalEvaluations"
                      :key="physicalEvaluation.id"
                      v-if="physicalEvaluation.type === fdType.value"
                    >
                      <v-list-item
                        @click="openPhysicalEvaluation(physicalEvaluation)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>
                              {{ str['physical_evaluation'] }}{{ showId ? (' ' + physicalEvaluation.id) : '' }} - 
                            </span>
                            <span v-bind:class="(physicalEvaluationStatusDict[physicalEvaluation.status] ? physicalEvaluationStatusDict[physicalEvaluation.status].type : 'default') + '--text'">
                              {{ physicalEvaluationStatusDict[physicalEvaluation.status] ? physicalEvaluationStatusDict[physicalEvaluation.status].label : str['n_a'] }}
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="showPhysicalEvaluationDateNext"
                          >
                            {{ physicalEvaluation.date }} / {{ physicalEvaluation.date_next }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="!showPhysicalEvaluationDateNext"
                          >
                            {{ physicalEvaluation.date }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-list>
            </div>
            <div
              v-if="physicalEvaluationTabs[physicalEvaluationTab].id === 'table' && physicalEvaluationsTable"
            >
              <div v-if="feedbackTypes && showTypeTabs">
                <v-tabs
                  v-model="physicalEvaluationTabType"
                  background-color="transparent"
                  color="secondary"
                  style="margin-top: 0;"
                  grow
                  show-arrows
                  @change="changePhysicalEvaluationTabType"
                >
                  <v-tab
                    v-for="fdType in feedbackTypes"
                    :key="fdType.id"
                  >
                    {{ str[fdType.tab] ? str[fdType.tab] : fdType.tab }}
                  </v-tab>
                </v-tabs>
              </div>
              <div
                v-if="showTypeTabs && feedbackTypes && feedbackTypes[physicalEvaluationTabType] && feedbackTypes[physicalEvaluationTabType].charts"
                class="row-align-right pt-2"
              >
                <v-btn-toggle
                  :rounded="true"
                  class="buttons-toggle-component"
                >
                  <v-btn
                    :color="viewMode === 'table' ? 'success' : 'transparent'"
                    @click="viewMode = 'table'"
                  >
                    <v-icon>
                      mdi-table
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :color="viewMode === 'chart' ? 'success' : 'transparent'"
                    @click="viewMode = 'chart'"
                  >
                    <v-icon>
                      mdi-chart-bar
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-data-table
                v-if="viewMode === 'table'"
                :headers="physicalEvaluationsTable.headers"
                :items="physicalEvaluationsFiltered"
                :no-data-text="str['no_data']"
                :no-results-text="str['no_data']"
                class="elevation-1"
                @click:row="openPhysicalEvaluation"
                :loading="isLoading"
                :loading-text="str['processing']"
                :custom-sort="customTableSort"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-if="!items.length && !isLoading">
                      <td
                        :colspan="physicalEvaluationsTable.headers.length"
                        class="text-center"
                      >
                        {{ str['no_data'] }}
                      </td>
                    </tr>
                    <tr
                      v-for="item in items"
                      :key="item.index"
                      @click="openPhysicalEvaluation(item)"
                    >
                      <td
                        v-for="(header, headerIndex) in physicalEvaluationsTable.headers"
                        :key="headerIndex"
                      >
                        <div v-if="header.value === 'id'">
                          {{ item.id }}
                        </div>
                        <div v-if="header.value === 'status'">
                          <span v-bind:class="(physicalEvaluationStatusDict[item.status] ? physicalEvaluationStatusDict[item.status].type : 'default') + '--text'">
                            {{ physicalEvaluationStatusDict[item.status] ? physicalEvaluationStatusDict[item.status].label : str['n_a'] }}
                          </span>
                          <div
                            v-if="client.employee1 && client.employee2 && client.flags & (1 << 3) && items[items.length - 1].id === item.id"
                            class="warning--text text-small"
                          >
                            {{ str['missing_validation'] }} {{ employeesDict[client.employee2] }}
                          </div>
                          <div
                            v-if="client.employee1 && client.employee2 && client.flags & (1 << 4) && items[items.length - 1].id === item.id"
                            class="warning--text text-small"
                          >
                            {{ str['missing_validation'] }} {{ employeesDict[client.employee1] }}
                          </div>
                        </div>
                        <div v-if="header.value === 'date'">
                          {{ item.id === 1 && !showType && !feedbackTypes ? str['initial_physical_evaluation'] : item.date }}
                        </div>
                        <div
                          v-if="header.value === 'type'"
                          class="text-center"
                        >
                          {{ physicalEvaluationTypesDict && physicalEvaluationTypesDict[item.type] ? physicalEvaluationTypesDict[item.type].name : (configTypesDict && configTypesDict[item.type] ? (str[configTypesDict[item.type].tab] ? str[configTypesDict[item.type].tab] : configTypesDict[item.type].tab) : '') }}
                        </div>
                        <div v-if="header.value === 'weight_variation'">
                          <div
                            v-if="item.index > 0"
                            class="values-variation-element row-flex-align-center"
                          >
                            <span>
                              <v-icon
                                v-if="getWeightVariation(item, item.index, 'kg') === 0"
                                small
                              >
                                mdi-equal
                              </v-icon>
                              <v-icon
                                v-if="getWeightVariation(item, item.index, 'kg') > 0"
                                small
                              >
                                mdi-chevron-up
                              </v-icon>
                              <v-icon
                                v-if="getWeightVariation(item, item.index, 'kg') < 0"
                                small
                              >
                                mdi-chevron-down
                              </v-icon>
                              {{ Math.abs(getWeightVariation(item, item.index, 'kg')) }}kg
                            </span>
                            <span>
                              <v-icon
                                v-if="getWeightVariation(item, item.index, '%') === 0"
                                small
                              >
                                mdi-equal
                              </v-icon>
                              <v-icon
                                v-if="getWeightVariation(item, item.index, '%') > 0"
                                small
                              >
                                mdi-chevron-up
                              </v-icon>
                              <v-icon
                                v-if="getWeightVariation(item, item.index, '%') < 0"
                                small
                              >
                                mdi-chevron-down
                              </v-icon>
                              {{ Math.abs(getWeightVariation(item, item.index, '%')) }}%
                            </span>
                          </div>
                        </div>
                        <div v-if="header.value === 'notes'">
                          <div
                            class="physical-evaluation-notes"
                            @click.stop="openPhysicalEvaluationNotes(item)"
                          >
                            <v-icon
                              small
                              class="mr-2"
                              color="warning"
                            >
                              mdi-pencil-box-outline
                            </v-icon>
                            <div>
                              {{ item.notes ? item.notes : str['n_a'] }}
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="header.value !== 'id' && header.value !== 'status' && header.value !== 'date' && header.value !== 'type' && header.value !== 'weight_variation' && header.value !== 'notes'"
                          class="text-center"
                        >
                          <span
                            v-if="physicalEvaluationsTable.values[item.index][header.value] && typeof physicalEvaluationsTable.values[item.index][header.value] === 'string' && physicalEvaluationsTable.values[item.index][header.value].includes(', ') && !str[physicalEvaluationsTable.values[item.index][header.value]]"
                            class="list-commas"
                          >
                            <span v-for="(subItem, subIndex) in physicalEvaluationsTable.values[item.index][header.value].split(', ')" :key="subIndex">
                              {{ str[subItem] ? str[subItem] : subItem }}
                            </span>
                          </span>
                          <span
                            v-else
                          >
                            {{ str[physicalEvaluationsTable.values[item.index][header.value]] ? str[physicalEvaluationsTable.values[item.index][header.value]] : physicalEvaluationsTable.values[item.index][header.value] }}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                  {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                </template>
              </v-data-table>
              <div v-if="viewMode === 'chart' && typeCharts">
                <div
                  v-for="(typeChart, typeChartIndex) in typeCharts"
                  :key="typeChartIndex"
                  class="chart-container"
                >
                  <base-material-chart-card
                    :data="typeChart"
                    :options="typeChart.options"
                    :type="typeChart.type"
                    :color="typeChart.color"
                    :size="typeChart.size"
                    :sizelimit="typeChart.sizelimit"
                    :shadow="true"
                    hover-reveal
                  >
                    <div
                      v-if="typeChart.title"
                      class="custom-chart-legend"
                    >
                      {{ typeChart.title }}
                    </div>
                    <div
                      v-if="typeChart.fields"
                      class="custom-chart-legend"
                    >
                      <div
                        v-for="(itemVal, indexVal) in typeChart.fields"
                        :key="indexVal"
                      >
                        <span :style="{backgroundColor: itemVal.color}"></span>
                        {{ itemVal.name }}
                      </div>
                    </div>
                  </base-material-chart-card>
                </div>
              </div>
            </div>
            <div
              v-if="physicalEvaluationTabs[physicalEvaluationTab].id === 'chart' && physicalEvaluationsChartData"
              class="chart-container"
            >
              <base-material-chart-card
                :data="physicalEvaluationsChartData"
                :options="physicalEvaluationsChartOptions"
                color="blue-grey darken-3"
                hover-reveal
                type="Line"
                :shadow="false"
              >
                <div class="custom-chart-legend">
                  <div
                    v-for="(itemVal, indexVal) in physicalEvaluationsChartData.fields"
                    :key="indexVal"
                  >
                    <span :style="{backgroundColor: itemVal.color}"></span>
                    {{ itemVal.name }}
                  </div>
                </div>
              </base-material-chart-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>

    <v-dialog
      v-model="dialogComparePhysicalEvaluations"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['compare_physical_evaluations'] }}
          </span>
        </v-card-title>

        <v-card-text class="client-compare-physical-evaluations">
          <div
            v-for="item in comparePhysicalEvaluationsList"
            :key="item.id"
            class="client-compare-physical-evaluations-item"
          >
            <div class="client-compare-physical-evaluations-item-keys">
              <div
                v-for="(key, keyIndex) in comparePhysicalEvaluationsKeys"
                :key="keyIndex"
              >
                <b>{{ key.name }}:</b> {{ item[key.id] }}
              </div>
            </div>
            <div>
              <div
                v-if="item.processing"
                class="client-compare-physical-evaluations-text"
              >
                {{ str['load_photos'] }}
              </div>
              <div
                v-if="item.photos && !item.photos.length && !item.processing"
                class="client-compare-physical-evaluations-text"
              >
                {{ str['no_photos'] }}
              </div>
              <div
                v-for="(photo, photoIndex) in item.photos"
                :key="photoIndex"
                class="client-compare-physical-evaluations-item-photo"
              >
                <div
                  class="icon-photo-download"
                  style="display: flex; flex-direction: column; align-items: center; padding: 0"
                >
                  <i
                    class="mdi mdi-tray-arrow-down secondary--text"
                    style="font-size: 1.2em; padding: 5px;"
                    @click="downloadImage(photo)"
                  />
                  <v-icon
                    v-if="showSortComparePhysicalEvaluationPhoto && photoIndex > 0"
                    color="primary"
                    @click.stop="moveComparePhysicalEvaluationPhoto(item, 'up', photoIndex)"
                    class="cursor-hover"
                    style="padding: 5px;"
                  >
                    mdi-arrow-up
                  </v-icon>
                  <v-icon
                    v-if="showSortComparePhysicalEvaluationPhoto && photoIndex !== item.photos.length - 1"
                    color="primary"
                    @click.stop="moveComparePhysicalEvaluationPhoto(item, 'down', photoIndex)"
                    class="cursor-hover"
                    style="padding: 5px;"
                  >
                    mdi-arrow-down
                  </v-icon>
                </div>
                <v-img
                  contain
                  :src="photo"
                  alt="Avaliação física"
                  style="margin: 0 auto;"
                  width="200px"
                />
              </div>
            </div>
          </div>
          <div
            v-if="comparePhysicalEvaluationsListIds.length < physicalEvaluations.length"
            class="client-compare-physical-evaluations-item"
          >
            <div
              v-if="!showPhysicalEvaluationsToCompare"
              class="client-compare-physical-evaluations-item-add-icon"
            >
              <v-icon
                class="me-2"
                @click="showPhysicalEvaluationsToCompare = true"
              >
                mdi-plus-circle
              </v-icon>
            </div>
            <div
              v-if="showPhysicalEvaluationsToCompare"
              class="client-compare-physical-evaluations-item-add-list"
            >
              <v-list two-line>
                <div
                  v-for="physicalEvaluation in physicalEvaluations"
                  :key="physicalEvaluation.id"
                  v-show="comparePhysicalEvaluationsListIds.indexOf(physicalEvaluation.id) === -1"
                >
                  <v-list-item
                    @click="addPhysicalEvaluationsToCompare(physicalEvaluation)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ str['physical_evaluation'] }}{{ showId ? (' ' + physicalEvaluation.id) : '' }}{{ typesDict && typesDict[physicalEvaluation.type] ? (' (' + typesDict[physicalEvaluation.type] + ')') : '' }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="showPhysicalEvaluationDateNext"
                      >
                        {{ physicalEvaluation.date }} / {{ physicalEvaluation.date_next }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="!showPhysicalEvaluationDateNext"
                      >
                        {{ physicalEvaluation.date }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </v-list>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogComparePhysicalEvaluations = false"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showPhysicalEvaluationsTable"
      v-model="dialogPhysicalEvaluationNotes"
      scrollable
      persistent
      max-width="400px"
    >
      <v-card>
        <div style="padding: 10px;">
          <v-textarea
            v-model="dialogPhysicalEvaluationNotesValue"
            :label="str['remarks']"
            :rows="3"
          />
        </div>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center;">
          <v-btn
            color="default"
            @click="closePhysicalEvaluationNotes()"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="updatePhysicalEvaluationNotes()"
            style="margin-right: 0;"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showChanges"
      v-model="dialogPhysicalEvaluationChanges"
      scrollable
      persistent
      max-width="650px"
    >
      <v-card v-if="dialogPhysicalEvaluationChangesData">
        <v-card-title>
          <span class="headline">
            {{ str['remarks'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="dialogPhysicalEvaluationChangesData.value"
            :rows="15"
            hide-details
          />
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center;">
          <v-btn
            color="default"
            @click="closePhysicalEvaluationChanges()"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="updatePhysicalEvaluationChanges()"
            style="margin-right: 0;"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showCreateButton && client && createDialogLink"
      v-model="dialogCreateDialog"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <iframe
              :src="createDialogLink + linkParameters"
              :name="Date.now()"
              style="width: 100%; height: calc(100vh - 250px); border: none;"
              allow="geolocation; microphone; camera; notifications;"
            ></iframe>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeCreateDialog()"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import moment from 'moment'

  export default {
    name: 'client-physical-evaluations',
    props: ['parent', 'client'],
    data() {
      const user = Utils.getUser()

      let showPhysicalEvaluationDateNext = true
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.date_next_hidden) {
        showPhysicalEvaluationDateNext = false
      }

      let showTabs = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_table && user.configurations.feedback.show_chart) {
        showTabs = true
      }

      let showPhysicalEvaluationsTable = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_table) {
        showPhysicalEvaluationsTable = true
      }

      let showPhysicalEvaluationsChart = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_chart) {
        showPhysicalEvaluationsChart = true
      }

      let showFeedbackEmployeeName = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_employee_name) {
        showFeedbackEmployeeName = true
      }

      let showWeightVariationAlert = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_weight_variation_alert) {
        showWeightVariationAlert = true
      }

      let showSortComparePhysicalEvaluationPhoto = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_sort_photos) {
        showSortComparePhysicalEvaluationPhoto = true
      }

      let showChanges = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_changes) {
        showChanges = true
      }

      let showId = true
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.hide_id) {
        showId = false
      }

      let showDiary = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_diary) {
        showDiary = true
      }

      let showNotes = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_notes) {
        showNotes = true
      }

      let showChartInsidePhysicalEvaluation = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_chart_inside) {
        showChartInsidePhysicalEvaluation = true
      }

      let showChartLegend = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_chart_legend) {
        showChartLegend = true
      }

      let showChartLastItems = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_chart_last_items) {
        showChartLastItems = user.configurations.feedback.show_chart_last_items
      }

      let showType = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_type) {
        showType = user.configurations.feedback.show_type
      }

      let showTypeTabs = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_type_tabs) {
        showTypeTabs = user.configurations.feedback.show_type_tabs
      }

      let feedbackTypes = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.tabs) {
        feedbackTypes = user.configurations.feedback.tabs
      }

      let saveTrainingPlanChanges = false
      if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.save_changes_feedback) {
        saveTrainingPlanChanges = user.configurations.training_plans.save_changes_feedback
      }

      let showCreateButton = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_create_button) {
        showCreateButton = user.configurations.feedback.show_create_button
      }

      let globalFields = []
      let showCalories = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.global_fields) {
        globalFields = user.configurations.feedback.global_fields
        const hasCalories = globalFields.find(function (g) {
          return g.id === 'calories'
        })
        if (hasCalories) {
          showCalories = true
        }
      }
      if (!globalFields.length) {
        globalFields.push({
          id: 'body',
          field: 'weight',
          label: 'weight'
        })
      }

      let globalTableFields = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.table) {
        globalTableFields = user.configurations.feedback.table
      }

      let globalChartFields = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.chart) {
        globalChartFields = user.configurations.feedback.chart
      }

      let hideCompareButton = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.hide_compare_button) {
        hideCompareButton = true
      }

      let hasFilterDates = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.has_filter_dates) {
        hasFilterDates = true
      }

      let chartExcludeTypes = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.chart_exclude_types) {
        chartExcludeTypes = user.configurations.feedback.chart_exclude_types
      }

      let tableZeroIfan = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.table_zero_ifan) {
        tableZeroIfan = true
      }

      const tabs = []
      if (showPhysicalEvaluationsTable) {
        tabs.push({
          id: 'table',
          title: window.strings['list'],
        })
      } else {
        tabs.push({
          id: 'list',
          title: window.strings['list'],
        })
      }
      if (showPhysicalEvaluationsChart) {
        tabs.push({
          id: 'chart',
          title: window.strings['evolution'],
        })
      }

      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: user,
        isLoading: false,
        showPhysicalEvaluationDateNext: showPhysicalEvaluationDateNext,
        showTabs: showTabs,
        showPhysicalEvaluationsTable: showPhysicalEvaluationsTable,
        showPhysicalEvaluationsChart: showPhysicalEvaluationsChart,
        showFeedbackEmployeeName: showFeedbackEmployeeName,
        showWeightVariationAlert: showWeightVariationAlert,
        showSortComparePhysicalEvaluationPhoto: showSortComparePhysicalEvaluationPhoto,
        showChanges: showChanges,
        showId: showId,
        showDiary: showDiary,
        showNotes: showNotes,
        showChartInsidePhysicalEvaluation: showChartInsidePhysicalEvaluation,
        showChartLegend: showChartLegend,
        showChartLastItems: showChartLastItems,
        showType: showType,
        showTypeTabs: showTypeTabs,
        feedbackTypes: feedbackTypes,
        saveTrainingPlanChanges: saveTrainingPlanChanges,
        showCreateButton: showCreateButton,
        globalFields: globalFields,
        globalTableFields: globalTableFields,
        globalChartFields: globalChartFields,
        hideCompareButton: hideCompareButton,
        hasFilterDates: hasFilterDates,
        chartExcludeTypes: chartExcludeTypes,
        tableZeroIfan: tableZeroIfan,
        showCalories: showCalories,
        openPhysicalEvaluationLoading: window.feedbackPhysicalEvaluation ? true : false,
        linkParameters: window.jsonAdmin && window.jsonAdmin.linkParameters ? window.jsonAdmin.linkParameters : '',
        physicalEvaluations: [],
        dialogComparePhysicalEvaluations: false,
        comparePhysicalEvaluationsList: [],
        comparePhysicalEvaluationsListIds: [],
        comparePhysicalEvaluationsKeys: [{
          id: 'name',
          name: window.strings['name'],
        }, {
          id: 'date',
          name: window.strings['date'],
        }, {
          id: 'weight',
          name: window.strings['weight'],
        }],
        showPhysicalEvaluationsToCompare: false,
        physicalEvaluationTabs: tabs,
        physicalEvaluationTab: 0,
        physicalEvaluationTabType: window.physicalEvaluationTabType ? window.physicalEvaluationTabType : 0,
        physicalEvaluationsTable: null,
        physicalEvaluationsChartData: null,
        dialogPhysicalEvaluationNotes: false,
        dialogPhysicalEvaluationNotesId: null,
        dialogPhysicalEvaluationNotesValue: '',
        physicalEvaluationsChartOptions: {},
        types: Utils.getFeedbackTypesDict(),
        physicalEvaluationStatusDict: this.getPhysicalEvaluationStatusDict(),
        physicalEvaluationTypesDict: this.getPhysicalEvaluationTypesDict(),
        dialogPhysicalEvaluationChanges: false,
        dialogPhysicalEvaluationChangesData: null,
        returnPhysicalEvaluationDate: null,
        typesDict: {},
        configTypesDict: {},
        chartColors: ['#7CB342', '#00ACC1', '#f4c63d', '#d17905', '#5a5255', '#59922b', '#0544d3', '#b94003', '#38c01d', '#4b5b20', '#6c67c6', '#442e5d', '#7ab841', '#efdf73', '#556999'],
        dialogCreateDialog: false,
        createDialogLink: null,
        viewMode: 'table',
        typeCharts: null,
        filterDatepickerMenu: false,
        filterDates: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        employeesDict: {},
      }
    },
    computed: {
      filterDatesText () {
        if (this.filterDates[0] && this.filterDates[1]) {
          return this.filterDates.join(' / ')
        } else {
          return ''
        }
      },
      physicalEvaluationsFiltered: function () {
        if (this.isLoading) {
          return []
        }
        if (this.feedbackTypes && this.showTypeTabs) {
          const active = this.feedbackTypes[this.physicalEvaluationTabType] && (this.feedbackTypes[this.physicalEvaluationTabType].value || this.feedbackTypes[this.physicalEvaluationTabType].value === 0) ? this.feedbackTypes[this.physicalEvaluationTabType].value : false
          if (active !== false) {
            return this.physicalEvaluations.filter(function (item) {
              return item.type === active
            })
          }
        }
        return this.physicalEvaluations
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getConfigTypesDict()
      this.getTypesDict()
      this.getPhysicalEvaluations()
      this.activeEventListener()
      this.validateEmployeesMissingFeedback()
    },
    methods: {
      getConfigTypesDict: function () {
        const items = this.user && this.user.configurations && this.user.configurations.feedback && this.user.configurations.feedback.tabs ? this.user.configurations.feedback.tabs : null
        if (items) {
          const dict = {}
          items.forEach(function (item) {
            dict[item.value] = item
          })
          this.configTypesDict = dict
        }
      },
      getTypesDict: function () {
        const dict = {}
        if (this.feedbackTypes) {
          this.feedbackTypes.forEach(function (t) {
            dict[t.value] = window.strings[t.tab] ? window.strings[t.tab] : t.tab
          })
        }
        this.typesDict = dict
      },
      refresh: function () {
        this.getPhysicalEvaluations()
      },
      changeDates: function () {
        this.filterDatepickerMenu = false
        this.typesTabCache = {}
        this.getPhysicalEvaluations()
      },
      validateEmployeesMissingFeedback: function () {
        if (this.client.employee1 && this.client.employee2 && (this.client.flags & (1 << 3) || this.client.flags & (1 << 4))) {
          this.getEmployees()
        }
      },
      getEmployees: function () {
        const self = this
        this.employeesDict = {}
        Api.getEmployees({
          fields: ['id', 'name']
        }, function(response) {
          if (response.success) {
            const dict = {}
            response.data.forEach(function (item) {
              dict[item.id] = item.name
            })
            self.employeesDict = dict
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getPhysicalEvaluations: function () {
        const self = this
        const useTypeTab = this.showTypeTabs && this.feedbackTypes && this.feedbackTypes[this.physicalEvaluationTabType] ? this.feedbackTypes[this.physicalEvaluationTabType] : null
        const fields = ['id', 'date', 'date_next', 'date_time', 'date_valid', 'employee_name', 'measurements', 'notes', 'status', 'type', 'changes', 'flags']
        this.physicalEvaluations = []
        this.isLoading = true
        if (this.showCalories) {
          fields.push('calories')
        }
        if (this.showPhysicalEvaluationsTable) {
          const tableFields = this.feedbackTypes && this.feedbackTypes[this.physicalEvaluationTabType] && this.feedbackTypes[this.physicalEvaluationTabType].table ? this.feedbackTypes[this.physicalEvaluationTabType].table : (this.globalTableFields ? this.globalTableFields : this.globalFields)
          if (tableFields) {
            const remarksFound = tableFields.find(function (f) {
              return f.id === 'remarks'
            })
            if (remarksFound) {
              fields.push('remarks')
            }
          }
        }
        if (useTypeTab && !window.feedbackPhysicalEvaluation && this.typesTabCache && this.typesTabCache[useTypeTab.value]) {
          return drawList(this.typesTabCache[useTypeTab.value])
        }
        const payload = {
          id: this.client.dbId,
          orderAsc: this.showPhysicalEvaluationsTable ? true : false,
          fields: fields
        }
        if (this.showDiary) {
          payload.types = [0, 1, 2, 3, 4, 5]
        }
        if (!window.feedbackPhysicalEvaluation && this.showTypeTabs && this.feedbackTypes && this.feedbackTypes.length) {
          if (this.feedbackTypes[this.physicalEvaluationTabType].values) {
            payload.types = this.feedbackTypes[this.physicalEvaluationTabType].values
          } else {
            delete payload.types
            payload.type = this.feedbackTypes[this.physicalEvaluationTabType].value
          }
        }
        if (this.hasFilterDates && !window.feedbackPhysicalEvaluation) {
          payload.startDate = this.filterDates[0]
          payload.endDate = this.filterDates[1]
        }
        Api.getPhysicalEvaluations(payload, function (response) {
          if (useTypeTab) {
            self.typesTabCache = self.typesTabCache ? self.typesTabCache : {}
            self.typesTabCache[useTypeTab.value] = response
          }
          drawList(response)
        })

        function drawList(response) {
          const trainsIds = []
          if (response.success) {
            if (self.showCreateButton) {
              response.data = response.data.sort((a, b) => new Date(a.date) - new Date(b.date))
            }
            const list = []
            let returnPhysicalEvaluation = null
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].index = i
              list.push(response.data[i])
              if (response.data[i].type === self.types.return && !returnPhysicalEvaluation) {
                returnPhysicalEvaluation = response.data[i]
              }
              if (response.data[i].remarks) {
                try {
                  response.data[i].remarks = typeof response.data[i].remarks === 'string' ? JSON.parse(response.data[i].remarks) : response.data[i].remarks
                  const trainFound = response.data[i].remarks.find(function (r) {
                    return r.id === 'train_id'
                  })
                  if (trainFound) {
                    trainsIds.push(parseInt(trainFound.value))
                  }
                } catch { }
              }
            }
            self.physicalEvaluations = list
            self.returnPhysicalEvaluationDate = returnPhysicalEvaluation ? returnPhysicalEvaluation.date : null

            if (trainsIds && trainsIds.length) {
              self.getTrains(trainsIds)
            }

            if (!useTypeTab) {
              const lastFeedback = response.data.reduce((currentItem, nextItem) => {
                return (currentItem.id > nextItem.id) ? currentItem : nextItem
              })
              if (lastFeedback) {
                Utils.saveRequestCache('last_physical_evaluation-' + self.client.dbId, {
                  success: true,
                  data: [lastFeedback]
                })
              }
            }
          }

          if (!trainsIds.length) {
            self.getPhysicalEvaluationsTable()
            self.getPhysicalEvaluationsTypeChart()
          }
          self.getPhysicalEvaluationsChart()

          if (window.feedbackPhysicalEvaluation && self.physicalEvaluations) {
            let opened = false
            for (let i = 0; i < self.physicalEvaluations.length; i++) {
              if (self.physicalEvaluations[i].id === window.feedbackPhysicalEvaluation) {
                opened = true
                self.openPhysicalEvaluation(self.physicalEvaluations[i])
                break
              }
            }
            window.feedbackPhysicalEvaluation = null
            if (!opened) {
              self.openPhysicalEvaluationLoading = false
            }
          }
        }
      },
      getPhysicalEvaluationStatusDict() {
        const dict = {}
        const list = Utils.getPhysicalEvaluationStatus()
        if (list && list.length) {
          list.forEach(function (item) {
            item.tab = window.strings[item.tab] ? window.strings[item.tab] : item.tab
            dict[item.value] = item
          })
        }
        return dict
      },
      getPhysicalEvaluationTypesDict() {
        const dict = {}
        const list =  Utils.getFeedbackTypes()
        if (list && list.length) {
          list.forEach(function (item) {
            item.name = window.strings[item.name] ? window.strings[item.name] : item.name
            dict[item.id] = item
          })
        }
        return dict
      },
      getTrains: function (ids) {
        const self = this
        if (ids && ids.length) {
          Api.getTrains({
            client_id: this.client.dbId,
            ids: ids,
            fast: 1
          }, function (response) {
            if (response.success) {
              let updated = false
              for (let i = 0; i < response.data.length; i++) {
                const index = self.physicalEvaluations.findIndex(function (p) {
                  let rFound = null
                  if (p.remarks) {
                    rFound = p.remarks.find(function (r) {
                      return r.id === 'train_id' && response.data[i].id === parseInt(r.value)
                    })
                  }
                  return rFound
                })
                if (index > -1) {
                  updated = true
                  self.physicalEvaluations[index].remarks.push({
                    id: 'train',
                    value: response.data[i].name
                  })
                  self.physicalEvaluations[index].remarks.push({
                    id: 'train_date',
                    value: response.data[i].date
                  })
                  self.$set(self.physicalEvaluations, index, self.physicalEvaluations[index])
                }
              }
              if (updated) {
                self.getPhysicalEvaluationsTable()
                self.getPhysicalEvaluationsTypeChart()
              }
            }
          })
        }
      },
      openPhysicalEvaluation: function (item) {
        Utils.setStorage('physicalEvaluation', item)
        Utils.setStorage('physicalEvaluationsList', this.physicalEvaluations)
        if (this.showChartInsidePhysicalEvaluation) {
          Utils.setStorage('physicalEvaluationsChart', {
            data: this.physicalEvaluationsChartData,
            options: this.physicalEvaluationsChartOptions
          })
        }
        this.$router.navigate.push({ path: '/home/physicalEvaluation' })
      },
      openPhysicalEvaluationsToCompare: function () {
        this.comparePhysicalEvaluationsList = []
        this.comparePhysicalEvaluationsListIds = []
        this.showPhysicalEvaluationsToCompare = false
        this.dialogComparePhysicalEvaluations = true
      },
      addPhysicalEvaluationsToCompare: function (physicalEvaluation) {
        const self = this
        let weight = '-'
        if (physicalEvaluation.body &&
          (physicalEvaluation.body.weight || physicalEvaluation.body.weight === 0)
        ) {
          weight = physicalEvaluation.body.weight
        }

        const phyEvalData = {
          id: physicalEvaluation.id,
          name: window.strings['physical_evaluation'] + (this.showId ? (' ' + physicalEvaluation.id) : '') + (this.typesDict && this.typesDict[physicalEvaluation.type] ? (' (' + this.typesDict[physicalEvaluation.type] + ')') : ''),
          date: physicalEvaluation.date + (this.showPhysicalEvaluationDateNext ? (' / ' + physicalEvaluation.date_next) : ''),
          photos: [],
          processing: true,
        }

        try {
          if (physicalEvaluation.measurements) {
            const measurements = JSON.parse(physicalEvaluation.measurements)
            const weightItem = measurements.find(function (item) {
              return item.id === 'weight'
            })
            if (weightItem) {
              weight = weightItem.value
            }
          }
          if (this.user && this.user.configurations && this.user.configurations.feedback && this.user.configurations.feedback.compare_fields) {
            this.user.configurations.feedback.compare_fields.forEach(function (item) {
              const indexFound = self.comparePhysicalEvaluationsKeys.findIndex(function (it) {
                return it.id === item.field
              })
              phyEvalData[item.field] = physicalEvaluation[item.id] && physicalEvaluation[item.id][item.field] ?
                (physicalEvaluation[item.id][item.field] || physicalEvaluation[item.id][item.field] === 0) : ''
              if (indexFound === -1) {
                self.comparePhysicalEvaluationsKeys.push({
                  id: item.field,
                  name: item.label ? (window.strings[item.label] ? window.strings[item.label] : item.label) : window.strings[item.field],
                })
              }
            })
          }
        } catch { }

        phyEvalData.weight = weight
        this.comparePhysicalEvaluationsList.push(phyEvalData)
        this.comparePhysicalEvaluationsListIds.push(physicalEvaluation.id)
        const index = this.comparePhysicalEvaluationsList.length - 1
        this.showPhysicalEvaluationsToCompare = false

        Api.getPhysicalEvaluationPhotos({
          client_id: this.client.dbId,
          id: physicalEvaluation.id,
        }, function (response) {
          if (response.success && response.data && response.data.length) {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].photo && response.data[i].type === 0 || !response.data[i].type) {
                self.comparePhysicalEvaluationsList[index].photos.push(response.data[i].photo)
              }
            }
          }
          self.comparePhysicalEvaluationsList[index].processing = false
          self.$set(self.comparePhysicalEvaluationsList, index, self.comparePhysicalEvaluationsList[index])
        })

        setTimeout(function () {
          const list = document.getElementsByClassName('client-compare-physical-evaluations')[0]
          list.scrollLeft = list.scrollWidth
        })
      },
      downloadImage: function (image) {
        const a = document.createElement('a')
        a.href = image
        a.download = 'image.png'
        a.click()
      },
      getPhysicalEvaluationsTable() {
        const self = this
        if (this.showPhysicalEvaluationsTable) {
          const fields = this.feedbackTypes && this.feedbackTypes[this.physicalEvaluationTabType] && this.feedbackTypes[this.physicalEvaluationTabType].table ? this.feedbackTypes[this.physicalEvaluationTabType].table : (this.globalTableFields ? this.globalTableFields : this.globalFields)
          const tableFields = []
          const tableValues = []
          const hasWidthCols = fields.length > 2 ? true : false
          let hasWeight = false
          const tableHeaders = []
          if (!this.showTypeTabs) {
            tableHeaders.push({ text: window.strings['id'], value: 'id', width: 60 })
          }
          tableHeaders.push(
            { text: window.strings['status'], value: 'status', width: hasWidthCols ? 125 : 'auto' },
            { text: window.strings['date'], value: 'date', width: hasWidthCols ? 130 : 'auto' }
          )
          if (this.showType) {
            tableHeaders.push({ text: window.strings['type'], value: 'type', width: hasWidthCols ? 170 : 'auto' })
          }
          fields.forEach(function (field, index) {
            const label = field.label ? (window.strings[field.label] ? window.strings[field.label] : field.label) : null
            tableHeaders.push({
              text: label,
              align: 'center',
              value: field.field ? field.field : field.id,
              width: field.col_width ? field.col_width : 150
            })
            field.name = label
            field.color = self.chartColors[index]
            tableFields.push(field)
            if (field.field === 'weight' && !field.hide_weight_variation) {
              hasWeight = true
            }
          })
          self.physicalEvaluations.forEach(function (item) {
            const obj = {}
            let remarks = null
            tableFields.forEach(function (field) {
              if (field.id === 'remarks') {
                remarks = remarks ? remarks : (item.remarks ? item.remarks : null)
                const remarksValue = remarks ? remarks.find(function (r) {
                  return r.id === field.field
                }) : null
                obj[field.field] = remarksValue ? remarksValue.value : ''
              } else {
                if (field.field) {
                  obj[field.field] = self.tableZeroIfan ? (item[field.id][field.field] ? item[field.id][field.field] : '-') : item[field.id][field.field]
                } else {
                  obj[field.id] = self.tableZeroIfan ? (item[field.id] ? item[field.id] : '-') : item[field.id]
                }
              }
            })
            tableValues.push(obj)
          })
          if (hasWeight) {
            tableHeaders.push({ text: window.strings['weight_variation'], align: 'center', value: 'weight_variation', width: hasWidthCols ? 150 : 'auto', sortable: false })
          }
          tableHeaders.push({ text: window.strings['remarks'], align: 'left', value: 'notes', width: hasWidthCols ? 250 : 'auto', sortable: false })
          this.physicalEvaluationsTable = {
            headers: tableHeaders,
            values: tableValues,
            fields: tableFields
          }
        }
        this.isLoading = false
      },
      customTableSort(items, sortBy, sortDesc) {
        const defaultHeaders = ['id', 'status', 'date', 'type', 'weight_variation', 'notes']
        sortBy = sortBy[0]
        sortDesc = sortDesc[0]
        if (defaultHeaders.includes(sortBy)) {
          return items.sort((a, b) => {
            const valueA = a[sortBy]
            const valueB = b[sortBy]
            if (sortDesc) {
              return valueA < valueB ? 1 : -1
            } else {
              return valueA > valueB ? 1 : -1
            }
          })
        }
        return items.sort((a, b) => {
          const valueA = this.physicalEvaluationsTable.values[a.index][sortBy]
          const valueB = this.physicalEvaluationsTable.values[b.index][sortBy]
          if (sortDesc) {
            return valueA < valueB ? 1 : -1
          } else {
            return valueA > valueB ? 1 : -1
          }
        })
      },
      getPhysicalEvaluationsChart(src) {
        const self = this
        if (this.showPhysicalEvaluationsChart || src) {
          const fields = src ? src : (this.globalChartFields ? this.globalChartFields : this.globalFields)
          const tableFields = []
          const chartSeries = []
          let phyEvalsClone = JSON.parse(JSON.stringify(this.physicalEvaluations))
          if (this.chartExcludeTypes) {
            phyEvalsClone = phyEvalsClone.filter(function (c) {
              return self.chartExcludeTypes.indexOf(c.type) === -1
            })
          }
          let phyEvals = phyEvalsClone.sort((a, b) => a.id - b.id)
          let max = 0
          let min = null
          if (this.showChartLastItems) {
            phyEvals = phyEvals.slice(this.showChartLastItems * -1)
          }
          fields.forEach(function (field, index) {
            if (!field.hide_in_chart) {
              const label = window.strings[field.label] ? window.strings[field.label] : field.label
              chartSeries.push(phyEvals.map(function (item) {
                let meta = ''
                let text = ''
                fields.forEach(function (metaField) {
                  const metaFieldLabel = window.strings[metaField.label] ? window.strings[metaField.label] : metaField.label
                  const metaFieldVal = metaField.field ? item[metaField.id][metaField.field] : item[metaField.id]
                  const metaFieldUnit = metaField.unit ? (window.strings[metaField.unit] ? window.strings[metaField.unit] : metaField.unit) : ''
                  if (meta === '') {
                    meta += window.strings['physical_evaluation'] + ' ' + item.id + '\n'
                    meta += item.date + '\n\n'
                    if (self.showChartLegend) {
                      text += item.date + (self.returnPhysicalEvaluationDate && self.returnPhysicalEvaluationDate >= item.date ? ' (R)' : '') + '\n'
                    }
                  } else {
                    meta += '\n'
                    text += ' | '
                  }
                  meta += '• ' + metaFieldLabel + ': ' + metaFieldVal
                  if (self.showChartLegend) {
                    text += metaFieldVal + metaFieldUnit
                  }
                })
                const val = field.field ? item[field.id][field.field] : item[field.id]
                max = val > max ? val : max
                min = min === null || val < min ? val : min
                return {
                  meta: meta,
                  text: text,
                  value: val
                }
              }))
              field.name = label
              field.color = self.chartColors[index]
              tableFields.push(field)
            }
          })
          const minCalc = min - 1
          const chartOpts = {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            plugins: [
              this.$chartist.plugins.tooltip({
                appendToBody: true,
              })
            ],
            low: minCalc > 0 ? minCalc : 0,
            high: max + 1
          }
          const chartData = {
            labels: this.showChartLegend && chartSeries[0] ? chartSeries[0].map(a => (a.text)) : phyEvals.map(a => ('')),
            series: chartSeries,
            fields: tableFields
          }
          if (src) {
            return {
              options: chartOpts,
              labels: chartData.labels,
              series: chartData.series,
              fields: chartData.fields
            }
          }
          this.physicalEvaluationsChartOptions = chartOpts
          this.physicalEvaluationsChartData = chartData
        }
      },
      getPhysicalEvaluationsTypeChart() {
        const self = this
        if (this.showTypeTabs) {
          const charts = this.feedbackTypes && this.feedbackTypes[this.physicalEvaluationTabType] && this.feedbackTypes[this.physicalEvaluationTabType].charts ? this.feedbackTypes[this.physicalEvaluationTabType].charts : null
          if (charts) {
            const chartsToDraw = []
            charts.forEach(function (chart) {
              if (chart.type === 'bar') {
                chartsToDraw.push(getChartBar(chart))
              }
              if (chart.type === 'line') {
                const lineChart = self.getPhysicalEvaluationsChart(chart.values)
                lineChart.type = chart.type.charAt(0).toUpperCase() + chart.type.slice(1),
                lineChart.title= chart.title ? (window.strings[chart.title] ? window.strings[chart.title] : chart.title) : null
                lineChart.color = 'blue-grey darken-3'
                chartsToDraw.push(lineChart)
              }
            })
            this.typeCharts = chartsToDraw
          } else {
            this.typeCharts = null
          }
        }

        function getChartBar(chart) {
          const labels = []
          const series = []
          const countAdded = []
          let max = 0
          for (let p = 0; p < self.physicalEvaluations.length; p++) {
            const item = self.physicalEvaluations[p]
            let xValue = getValue(chart.x_value, item)
            let yValue = ''
            if (chart.y_value.count) {
              if (countAdded.indexOf(xValue) > -1) {
                continue
              }
              countAdded.push(xValue)
              let valueCount = 0
              self.physicalEvaluations.forEach(phy => {
                if (phy.remarks && phy.remarks.some(r => r.value === xValue)) {
                  valueCount++
                }
              })
              yValue = valueCount
            } else {
              yValue = getValue(chart.y_value, item)
            }
            labels.push(xValue)
            series.push({
              label: xValue,
              meta: (chart.x_value.field === 'train' ? getValue({parent: 'remarks', field: 'train_date'}, item) : item.date) + '\n' + xValue + ': ' + yValue,
              value: yValue
            })
            max = yValue > max ? yValue : max
          }
          return {
            type: chart.type.charAt(0).toUpperCase() + chart.type.slice(1),
            options: {
              lineSmooth: self.$chartist.Interpolation.cardinal({
                tension: 0,
              }),
              low: 0,
              high: max + (chart.offset ? chart.offset : 0),
              chartPadding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              plugins: [
                self.$chartist.plugins.tooltip({
                  appendToBody: true,
                })
              ]
            },
            title: chart.title ? (window.strings[chart.title] ? window.strings[chart.title] : chart.title) : null,
            color: 'blue-grey darken-3',
            labels: labels,
            series: [series],
            size: '200',
            sizelimit: '200'
          }
        }

        function getValue(src, item) {
          if (src.calc) {
            const calc1 = parseFloat(getValue(src.val1, item))
            const calc2 = parseFloat(getValue(src.val2, item))
            switch (src.calc) {
              case 'multiplication':
                return calc1 * calc2
              case 'sum':
                return calc1 + calc2
              case 'subtract':
                return calc1 - calc2
            }
          }
          if (src.parent === 'remarks') {
            const xVal = item.remarks.find(function (r) {
              return r.id === src.field
            })
            return xVal ? xVal.value : ''
          }
          if (src.parent) {
            return item[src.parent][src.field]
          }
          return item[src.field]
        }
      },
      openPhysicalEvaluationNotes(item) {
        this.dialogPhysicalEvaluationNotesId = item.id
        this.dialogPhysicalEvaluationNotesValue = item.notes
        this.dialogPhysicalEvaluationNotes = true
      },
      closePhysicalEvaluationNotes() {
        this.dialogPhysicalEvaluationNotes = false
        this.dialogPhysicalEvaluationNotesId = null
        this.dialogPhysicalEvaluationNotesValue = ''
      },
      updatePhysicalEvaluationNotes() {
        const self = this
        Api.editPhysicalEvaluation({
          client_id: this.client.dbId,
          id: this.dialogPhysicalEvaluationNotesId,
          notes: this.dialogPhysicalEvaluationNotesValue
        }, function (response) {
          if (response.success) {
            const index = self.physicalEvaluations.findIndex(function (item) {
              return response.data.id === item.id
            })
            if (index > -1) {
              self.physicalEvaluations[index].notes = response.data.notes
              self.$set(self.physicalEvaluations, index, self.physicalEvaluations[index])
            }
            self.closePhysicalEvaluationNotes()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      changePhysicalEvaluationTabType: function () {
        window.physicalEvaluationTabType = this.physicalEvaluationTabType
        this.viewMode = 'table'
        if (this.showTypeTabs && this.feedbackTypes && this.feedbackTypes[this.physicalEvaluationTabType]) {
          this.refresh()
        }
      },
      moveComparePhysicalEvaluationPhoto: function (item, position, index) {
        const photos = JSON.parse(JSON.stringify(item.photos))
        let newIndex = null
        if (position === 'up' && index > 0) {
          newIndex = index - 1
        }
        if (position === 'down' && index < photos.length - 1) {
          newIndex = index + 1
        }
        if (newIndex !== null) {
          photos.splice(index, 1)
          photos.splice(newIndex, 0, item.photos[index])
          item.photos = photos
        }
      },
      getWeightVariation(item, index, unit) {
        if (unit === '%') {
          if (this.physicalEvaluations[index - 1].body.weight > 0) {
            return (((item.body.weight - this.physicalEvaluations[index - 1].body.weight) / this.physicalEvaluations[index - 1].body.weight) * 100).toFixed(1)
          } else {
            return 0
          }
        } else {
          return (item.body.weight - this.physicalEvaluations[index - 1].body.weight).toFixed(1)
        }
      },
      openPhysicalEvaluationChanges(item) {
        this.dialogPhysicalEvaluationChangesData = {
          id: item.id,
          name: item.name,
          value: item.changes
        }
        this.dialogPhysicalEvaluationChanges = true
      },
      closePhysicalEvaluationChanges() {
        this.dialogPhysicalEvaluationChanges = false
        this.dialogPhysicalEvaluationChangesData = null
      },
      updatePhysicalEvaluationChanges: function () {
        const self = this
        this.$isLoading(true)
        Api.editPhysicalEvaluation({
          client_id: this.client.dbId,
          id: this.dialogPhysicalEvaluationChangesData.id,
          changes: this.dialogPhysicalEvaluationChangesData.value
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const index = self.physicalEvaluations.findIndex(function (item) {
              return response.data.id === item.id
            })
            if (index > -1) {
              self.physicalEvaluations[index].changes = response.data.changes
              self.$set(self.physicalEvaluations, index, self.physicalEvaluations[index])
            }
            self.closePhysicalEvaluationChanges()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      showInformation: function (message) {
        this.$alert(
          message,
          '',
          '',
          Utils.getAlertOptions()
        )
      },
      openCreateDialog: function () {
        if (this.showCreateButton && this.showCreateButton.link) {
          this.createDialogLink = this.showCreateButton.link.replace('<client_id>', this.client.id) + '&timestamp=' + new Date().getTime()
          this.dialogCreateDialog = true
        }
      },
      closeCreateDialog: function () {
        this.dialogCreateDialog = false
        this.createDialogLink = null
      },
      activeEventListener: function () {
        if (this.showCreateButton && this.showCreateButton.link) {
          window.addEventListener('message', this.createdEventListener)
        }
      },
      removeEventListener: function () {
        if (this.showCreateButton && this.showCreateButton.link) {
          window.removeEventListener('message', this.createdEventListener)
        }
      },
      createdEventListener: function (event) {
        try {
          if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
            const data = JSON.parse(event.data)
            if (data && data.event === 'physical-evaluation-created') {
              this.closeCreateDialog()
              this.refresh()
            }
          }
        } catch { }
      },
    },
    beforeDestroy: function () {
      this.removeEventListener()
    },
  }
</script>
